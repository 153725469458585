<template>
	<div>
		<div class="content">
			<!-- 修改密码 -->
			<div class="mb20 pageTitle"><b>{{$t('main.systemMenu.changePassword')}}</b></div>
			<div>
				<el-form class="form" label-width="120px" v-loading="loading">
					<el-form-item :label="$t('main.systemMenu.originalPassword')">
						<el-input prefix-icon="el-icon-lock" type="password" :placeholder="$t('main.systemMenu.pleaseEnterOriginalPassword')" v-model="oldpassword"></el-input>
					</el-form-item>
					<el-form-item :label="$t('main.systemMenu.newPassword')">
						<el-input prefix-icon="el-icon-lock" type="password" :placeholder="$t('main.systemMenu.pleaseEnterNewPassword')" v-model="password"></el-input>
					</el-form-item>
					<el-form-item :label="$t('main.systemMenu.confirmPassword')">
						<el-input prefix-icon="el-icon-lock" type="password" :placeholder="$t('main.systemMenu.pleaseEnterTheNewPasswordAgain')" v-model="comPassword"></el-input>
					</el-form-item>
					<el-form-item :label="$t('main.systemMenu.googleVerificationCode')">
						<div class="flexX">
							<el-input prefix-icon="el-icon-key" :placeholder="$t('main.systemMenu.pleaseEntergoogleVerificationCode')" v-model="code" clearable></el-input>
						</div>
					</el-form-item>
					<el-form-item>
						<el-button type="primary" @click="onSubmit">{{$t('main.systemMenu.submit')}}</el-button>
					</el-form-item>
				</el-form>
			</div>
		</div>
	</div>
</template>

<script>
	import {
		isEmpty,
		isPositiveNumber,
		isMoney
	} from '@/util/validate'
	import {
		Message
	} from 'element-ui'
	export default {
		data() {
			return {
				oldpassword:'',
				password:'',
				comPassword:'',
				code:'',
				loading:false
			}
		},
		created() {
		},
		methods: {
			onSubmit(){
				if(isEmpty(this.oldpassword,"原密码不能为空")){
					return
				}
				if(isEmpty(this.password,"新密码不能为空")){
					return
				}
				if(this.password != this.comPassword){
					this.$message({type:"error",message:"两次输入密码不一致"})
					return
				}				
				if(isEmpty(this.code,"谷歌验证码不能为空")){
					return
				}
				let data={
					old_password:this.$md5(this.oldpassword),
					password:this.$md5(this.password),
					code:this.code
				}
				this.loading = true;
				this.$api.setMannerPassword(data).then(res=>{
					this.loading = false
					if(res.status == 1){
						this.$message({type:"success",message:"密码修改成功"})
						this.oldpassword = '',
						this.password = '',
						this.comPassword = '',
						this.code = '',
						this.$local.remove('fourtid')
						this.$local.remove('fourusername')
						this.$router.push('/')
					}else{
						this.$message({type:"error",message:res.msg})
					}
				}).catch(error=>{
					this.loading = false
				})
			}
		},
	}
</script>

<style lang="scss" scoped="scoped">
	@import '@/style/variables.scss';

	.content {
		.pageTitle {
			background-color: $navSubColor;
			padding: 10px;
		}
	
		.tips {
			width: 460px;
		}
	
		.form {
			width: 460px;
		}
	}
</style>
